
import View from '@/apps/map/views/home';
import { getCustomServerSideProps, GetServerSidePropsContext } from '@/utils/serverSide';

export default View;

export const getServerSideProps = async (
    context: GetServerSidePropsContext,
) => {
    return getCustomServerSideProps(
        context,
        {
            config: {
                layout: {
                    name: 'map',
                },
                protected: false,
            },
        },
        {
            redirectIfLogged: false,
            seoConfig: {
                namespace: 'insider',
                title: 'AI Map',
                description: 'A tool for planning routes and finding the best places to visit.'
            },
        },
    );
};
