import Wave from 'react-wavify';

import { ButtonLink } from '@/components/Link';
import Wrapper from '@/components/MdxViewer';
import { useTranslate } from '@/hooks/useTranslate';
import AnimatedBackgroundComponent from '@/snippets/AnimatedBackground';
import { Box, Container, Typography, useTheme } from '@mui/material';

import ContentEn from './content_en.mdx';
import ContentRu from './content_ru.mdx';

import routes from '@/apps/map/core/routes';

const View = () => {
    const { t, lang } = useTranslate('map');
    const theme = useTheme();

    return (
        <Box>
            <Box sx={{
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'hidden',
                minHeight: {
                    xs: '70vh',
                    md: '80vh',
                },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                py: {
                    xs: 10,
                    md: 20,
                },
                px: {
                    xs: 5,
                    md: 0,
                }
            }}>
                <AnimatedBackgroundComponent sx={{
                    zIndex: 1,
                    opacity: 0.5,
                }} />
                <Container maxWidth="md">
                    <Box sx={{
                        position: 'relative',
                        zIndex: 2,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: {
                            xs: 10,
                            md: 15,
                        },
                    }}>
                        <Box>
                            <Typography variant="h1" component="h2" align='center' mb={5}>
                                {t('home.title')}
                            </Typography>
                            <Typography variant="h4" align='center'>
                                {t('home.subtitle')}
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: {
                                xs: 'column',
                                md: 'row'
                            },
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            gap: {
                                xs: 3,
                                md: 5,
                            },

                            '& .MuiButton-root': {
                                px: {
                                    xs: 5,
                                    md: 10
                                },
                                py: {
                                    xs: 2,
                                    md: 5,
                                },
                                borderRadius: 50,
                                fontSize: {
                                    xs: 18,
                                    md: 22,
                                },
                            }
                        }}>
                            <ButtonLink
                                size="large"
                                href={routes.finder.path}
                                variant="contained"
                                color="warning">
                                {t('home.make-route')}
                            </ButtonLink>
                        </Box>
                    </Box>
                </Container>
                <Wave
                    fill={theme.palette.background.default}
                    paused={false}
                    style={{
                        pointerEvents: 'none',
                        position: 'absolute',
                        bottom: -25,
                        left: 0,
                        width: '100%',
                        zIndex: 2,
                    }}
                    options={{
                        height: 110,
                        amplitude: 20,
                        speed: 0.15,
                        points: 10,
                    }}
                />
            </Box>

            <Box sx={{
                py: {
                    xs: 10,
                    md: 20,
                }
            }}>
                <Container maxWidth="lg">
                    <Wrapper Component={lang === 'ru' ? ContentRu : ContentEn} />
                </Container>
            </Box>
        </Box>
    );
};

export default View;